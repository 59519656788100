// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-illustrazione-modello-integrato-js": () => import("./../../../src/pages/illustrazione-modello-integrato.js" /* webpackChunkName: "component---src-pages-illustrazione-modello-integrato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mappa-sito-js": () => import("./../../../src/pages/mappa-sito.js" /* webpackChunkName: "component---src-pages-mappa-sito-js" */),
  "component---src-pages-privacy-verifica-chiamate-ricevute-js": () => import("./../../../src/pages/privacy/verifica-chiamate-ricevute.js" /* webpackChunkName: "component---src-pages-privacy-verifica-chiamate-ricevute-js" */),
  "component---src-templates-long-form-js": () => import("./../../../src/templates/long-form.js" /* webpackChunkName: "component---src-templates-long-form-js" */),
  "component---src-templates-newsroom-js": () => import("./../../../src/templates/newsroom.js" /* webpackChunkName: "component---src-templates-newsroom-js" */),
  "component---src-templates-pagina-interna-js": () => import("./../../../src/templates/pagina-interna.js" /* webpackChunkName: "component---src-templates-pagina-interna-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-report-bilancio-js": () => import("./../../../src/templates/report-bilancio.js" /* webpackChunkName: "component---src-templates-report-bilancio-js" */),
  "component---src-templates-short-form-js": () => import("./../../../src/templates/short-form.js" /* webpackChunkName: "component---src-templates-short-form-js" */)
}

